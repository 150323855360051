$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;

$black-color: #000000;
$white-color: #ffffff;
$light-gray: #d3d3d3;

$light-border-color: rgb(195, 195, 195);
$box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;

:root {
  --black: #{$black-color};
  --white: #{$white-color};
  --light-gray: #{$light-gray};
  --box-shadow: #{$box-shadow};
  --light-border-color: #{$light-border-color};
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  @include sm {
    width: 576px;
  }
  @include md {
    width: 750px;
  }
  @include lg {
    width: 970px;
  }
  @include xl {
    width: 1170px;
  }
}
.column {
  padding: 6px;
  width: 100%;
  @include sm {
    width: 100%;
  }
  @include md {
    width: 50%;
  }
  @include lg {
    width: 33.33333333%;
  }
  @include xl {
    max-width: 25%;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.btn {
  height: 100%;
  height: 40px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  border: 1px solid;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 8px;
  cursor: pointer;
}
