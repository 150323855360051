:root {
  --bright-color: #5f8eb1;
  --transparent-blue-color: #d4e5ff;
  --dark-blue-color: #0e2343;
  --main-color: #606060;
  --line-color: #bcb8c9;
  --light-grey-color: #e1e1e1;
  --transparen-grey-color: #f8f8f8;
  --font-family: "Open Sans", sans-serif;
  --secondary-font-family: "Oswald", sans-serif;
  --bg-color: #ffffff;
  --border-color: #dee2e6;
}

/* .Mui-focusVisible {
  box-shadow: none;
} */

body,
html {
  font-family: "Open Sans", sans-serif;
  font-weight: regular;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-transform: none;
  color: var(--main-color);
  line-height: 1.6em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  max-width: 100%;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
}

a,
div,
span,
img {
  outline: none;
}

.light-blue-heading {
  color: var(--bright-color);
}

.pl-50 {
  padding-left: 50px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-10 {
  padding-right: 10px;
}

.ml-mr-0 {
  margin-left: 0;
  margin-right: 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-20 {
  margin-top: 20px;
}

.mt--38 {
  margin-top: -38px;
}

.ml-5p {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-1r {
  margin-right: 1rem;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.full-width {
  width: 100%;
}

.align-self-center {
  align-self: center;
}

.table-row-active {
  background-color: rgb(230, 230, 255);
}

.margin-15-0 {
  margin: 15px 0;
}

.main-text-color {
  color: var(--dark-blue-color);
}

.link {
  color: var(--bright-color);
  font-size: 1rem;
  font-weight: bold;
}

.link:hover {
  text-decoration: none;
}

.icon-link {
  color: inherit;
}

.icon-link:hover {
  color: inherit;
  text-decoration: none;
}
.disable-button-style {
  width: 100% !important;
  background: none !important;
  border: none !important;
  padding: 0 !important;
  font: inherit !important;
  font-weight: bold !important;
  font-family: inherit !important;
  cursor: pointer !important;
  outline: inherit !important;
}

.disable-button-style-melee {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  font: inherit !important;
  font-weight: bold !important;
  font-family: inherit !important;
  cursor: pointer !important;
  outline: inherit !important;
}

.lab-name-link {
  text-decoration: underline;
}

.no-border {
  border: 0;
}

.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.react-colorful {
  width: 100% !important;
}

.home-page {
  flex-grow: 1;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

footer.page-footer {
  background: #8A8A6D; /* Greenish shade as per the screenshot */
  color: #FFFFFF; /* Adjust text color for better contrast */
  justify-content: end;
}

footer.page-footer .footer-copyright {
  overflow: hidden;
  color: #FFFFFF; /* Match text color with the footer background */
  font-size: small;
  border-top: 1px solid #6B6B51; /* Slightly darker shade for the border */
}

.footer-copyright a {
  color: #FFFFFF;
  text-decoration: none;
}

.footer-copyright a:hover {
  color: #FFD700; /* Bright color on hover (gold-like) */
  text-decoration: underline;
}

.partners {
  justify-content: center;
  padding-bottom: 20px;
}

.partner-container .partner-logo {
  width: 7%;
  margin-left: 10px;
}

.list-container {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: 1fr;
  max-height: 200px;
  overflow: auto;
  width: 100%;
}

.home-contact-person {
  padding: 0 10px;
}

.home-login-container {
  margin-right: 30px;
  width: 100%;
}

.home-login-container .login-form-title {
  margin-left: 0;
}

.home-loggedin-container {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  width: 100%;
}

.login-menu {
  width: 100%;
  padding: 12px 24px;
  border-bottom: 1px solid var(--line-color);
  font-size: 11px;
  letter-spacing: 1.1px;
  height: 44px;
  text-transform: uppercase;
  color: var(--dark-blue-color);
}

.login-menu span {
  vertical-align: middle;
}

.login-menu-item-collapse {
  text-transform: uppercase;
}

.customer-service-box,
.phone-box,
.login-form-container,
.wishlist-prieview-container,
.cart-preview-container,
.login-menu-item-right,
.logout-container {
  display: inline-block;
}

.customer-service-box,
.icon-User,
.icon-Heart,
.icon-ShoppingCart,
.icon-Exit,
.icon-Settings {
  padding-right: 5px;
  font-size: 13px;
}

.icon-Exit,
.icon-Settings {
  padding-left: 23px;
}

.login-form-container a,
.wishlist-prieview-container a,
.cart-preview-container a,
.logout-container a {
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.fa-calendar-plus,
.fa-globe-americas,
.fa-map-marker-alt {
  margin-right: 5px;
}

.card-img-container {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* object-fit: cover; */
}

.login-form-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.social-networks-container {
  display: inline-block;
}

.social-networks-container li a {
  text-decoration: none;
  color: inherit;
  padding-right: 6px;
}

.social-networks-container li a :hover {
  color: var(--bright-color);
}

.social-networks-container li {
  display: inline;
}

.header-contact-us {
  display: inline-block;
  cursor: pointer;
  height: 100%;
  padding-right: 13px;
  border-right: 1px solid var(--line-color);
}

.customer-service-box > span,
.customer-service-box > a {
  vertical-align: middle;
}

.wishlist-prieview-container a,
.header-contact-us > a,
.logout-container > a {
  text-decoration: none;
  color: inherit;
}

.login-form-container a:hover,
.wishlist-prieview-container a:hover,
.header-contact-us a:hover,
.logout-container:hover {
  color: var(--bright-color);
}

.cart-preview-container a:hover span {
  color: var(--bright-color);
}

.login-menu-item-right {
  float: right;
  margin-right: -5px;
}

.brand-logo {
  width: 100%;
  max-width: 170px;
  height: auto;
  max-height: 46px;
}

@media screen and (min-width: 1180px) and (max-width: 1520px) {
  .brand-logo {
    width: 100%;
    min-width: 100px;
    max-width: 170px;
    height: auto;
    max-height: 46px;
  }
  .social-button.fb {
    margin-left: 15px;
    font-size: 18px !important;
  }

  .social-button.gram {
    margin-left: 5px;
    font-size: 20px !important;
    padding-top: 2px;
  }
}
.fixed-top {
  background-color: var(--bg-color);
}

.shadow {
  box-shadow: 0 2px 20px 0 var(--line-color) !important;
}

.nav-link {
  text-decoration: none;
  color: inherit;
  font-size: 13px;
  text-transform: uppercase;
  padding: 4px 0;
  letter-spacing: 1.1px;
  outline: 0;
}

.nav-item {
  padding-left: 9px;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: red;
}

.navbar-light .navbar-nav .active > .nav-link {
  color: var(--bright-color);
}

.login-menu-item-collapse {
  display: none;
}

.dropdown-menu {
  font-size: inherit;
  text-align: center;
  border-radius: 0 !important;
  border: 1px solid var(--line-color);
  border-top: none;
  padding: 10px 20px;
  top: 11px !important;
  left: -10px !important;
  color: inherit;
}

.dropdown-menu button {
  width: 100%;
}

.dropdown-item:active {
  background-color: rgba(212, 229, 255, 0.2);
}

.product-img img,
.product-cart-img img {
  width: 70px;
  height: 70px;
  margin-right: 10px;
}

.shopping-list {
  list-style: none;
  padding-left: 0;
  width: 260px;
  font-size: inherit;
  text-transform: none;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: left;
  height: 92px;
  overflow-y: auto;
  overflow-x: hidden;
}

.product-heading {
  font-weight: 600;
  text-decoration: none;
  color: var(--dark-blue-color);
  margin-bottom: 14px;
}

.parameter {
  color: var(--main-color);
}

.product-heading:hover {
  text-decoration: underline;
}

.product-info {
  margin-bottom: 14px;
}

.media-subtotal {
  text-align: center;
  margin-top: 14px;
}

.media-subtotal span {
  margin-right: 20px;
}

.shopping-subtotal ul {
  padding-left: 0;
}

.shopping-subtotal ul li {
  list-style: none;
}

/* .main-menu {
  border-bottom: 1px solid var(--line-color);
} */

.container-fluid .home-row {
  height: 100%;
}

.container-fluid .home-row .home-image {
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: contain;
}

.home-image {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
}

.custom-toggler.navbar-toggler {
  border: 1px solid var(--line-color);
  margin-right: 0.6rem;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(75, 74, 74)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.category-title {
  font-size: 34px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 2px;
  color: var(--dark-blue-color);
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="url"],
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="range"],
input[type="color"],
select,
textarea {
  height: 42px;
  min-height: 42px;
  color: var(--main-color);
  font-size: 13px;
  margin-bottom: 15px;
  border-color: var(--line-color);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  outline: 0;
}

.form-control-textarea {
  min-height: 42px;
  color: var(--line-color);
  font-size: 13px;
  margin-bottom: 15px;
  border-color: var(--line-color);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  outline: 0;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus,
select.form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
  border-color: var(--bright-color);
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.btn-link {
  padding: 0;
  color: var(--main-color);
  font-style: oblique;
}

.btn-link:hover {
  color: var(--bright-color);
}

.btn-custom-outline {
  background: transparent;
  color: var(--dark-blue-color);
  border: 1px solid var(--dark-blue-color);
  padding: 0 20px;
  cursor: pointer;
  min-height: 42px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 20px 0;
  font-size: 13px;
  font-size: 11px;
}

.no-margin {
  margin: 0;
}

.btn-custom-outline:disabled,
.btn-custom-outline[disabled] {
  border: 1px solid var(--main-color);
  color: var(--main-color);
}

.btn-custom-outline:hover:not([disabled]) {
  color: var(--bright-color);
  border: 1px solid var(--bright-color);
}

.btn-custom-fill {
  background: var(--dark-blue-color);
  color: var(--bg-color);
  border: 1px solid var(--dark-blue-color);
  padding: 0 20px;
  cursor: pointer;
  min-height: 42px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 20px 0;
  font-size: 13px;
  font-size: 11px;
}

.btn-custom-fill:disabled,
.btn-custom-fill[disabled] {
  border: 1px solid var(--main-color);
  background: var(--main-color);
}

.btn-remove {
  color: #d9534f;
  cursor: pointer;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #d9534f;
}

.btn-remove:hover {
  color: var(--bg-color);
  background: #d9534f;
}

.btn-custom-fill:hover:not([disabled]) {
  background: var(--bright-color);
  border: 1px solid var(--bright-color);
}

.login-form,
.register-container,
.register-form-container {
  text-align: left;
  margin-top: 30px;
}

.login-form-title {
  margin-left: 19%;
}

.register-form-title {
  margin-right: 19%;
}

.login-form p {
  margin-bottom: 40px;
}

.register-container p {
  margin-bottom: 12px;
}

.register-form-container button {
  margin-left: 5px;
}

.login-form form {
  width: 62%;
  margin: 0 auto;
}

.page-category-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 1px;
}
.select-label {
  font-size: 16px;
  font-weight: bold;
}

.form-select {
  border-radius: 0;
  border-color: var(--line-color);
  font-size: 13px;
}

.reg-form {
  width: 73%;
  margin: 0 auto;
}

.reg-form-error {
  margin-top: 15px;
  padding: 0 5px;
}

.confirm-msg {
  margin-top: 56px;
}

.confirm-msg p {
  width: 50%;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-top: 30px;
  margin-bottom: 56px;
}

.reset-msg p {
  width: 40%;
  margin: 38px auto;
  text-align: center;
}

.reset-pass .btn-custom-fill {
  width: 100%;
}

.reset-pass form {
  width: 45%;
  margin: 0 auto;
}

.nav-sidenav {
  display: block;
  list-style: none;
  margin-top: 30px;
}

.nav-sidenav a {
  text-decoration: none;
  color: inherit;
  line-height: 30px;
}

.nav-sidenav a:hover {
  text-decoration: underline;
  font-weight: 600;
  color: var(--bright-color);
}

.side-bar {
  text-align: right;
  padding: 30px;
}

.section-heading {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  color: var(--bright-color);
}

.box-title h3,
.box-head h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.4px;
  color: var(--dark-blue-color);
}

.details-container,
.overview-container,
.wishlist-container,
.orders-container,
.order-created-container {
  border-left: 1px solid var(--line-color);
  padding: 20px 40px;
  margin: 0 auto;
  margin-bottom: 50px;
  border: none;
}

.details-container a {
  display: inline-block;
  padding: 8px 20px;
  text-decoration: none;
}

.col2-set .col-1 {
  float: left;
}

.box-account .col-1,
.box-account .col-2,
.address-list .col-1,
.address-list .col-2 {
  display: inline-block;
  min-width: 280px;
  padding: 0;
}

.box-account .col2-set {
  float: left;
  width: 100%;
  padding-bottom: 24px;
  padding-top: 24px;
}

.box-title,
.box-content,
.box-content-2 {
  clear: both;
  float: left;
  width: 80%;
}

.box-title {
  margin-bottom: 20px;
}

.box-title button {
  float: right;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.box-title h3,
.box-title h4 {
  float: left;
  font-weight: 700;
}

.welcome-msg {
  margin-bottom: 52px;
  width: 91%;
}

.my-account .page-title {
  margin-bottom: 12px;
}

.small-fill-btn {
  padding: 0px 10px;
  cursor: pointer;
  min-height: 24px;
  font-size: 10px;
  height: 26px;
  letter-spacing: 2px;
  margin: 0;
}

.small-outline-btn {
  min-height: 30px;
  height: 30px;
  font-size: 10px;
}

.box-head h3 {
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.4px;
  color: var(--dark-blue-color);
}

.welcome-msg > .hello {
  margin-top: 30px;
  font-size: 18px;
  color: var(--bright-color);
}

.box-content h4,
.box-content-2 h4 {
  margin-bottom: 20px;
}

.box-content-2 {
  margin-top: 20px;
}

.main-info {
  border-bottom: solid 1px var(--line-color);
}

#edit_personal_information {
  margin-top: 50px;
}

#edit_personal_information h3 {
  margin-bottom: 14px;
}

.bold-heading {
  font-weight: 600;
}

#change-pass-container {
  display: none;
}

.my-account-container,
.order-history-table-container {
  padding: 0 60px;
}

.addNewAddress {
  float: right;
  margin-top: -44px;
}

.order-history-container a {
  padding: 0;
  text-decoration: none;
  color: inherit;
}

.order-history-table {
  min-width: 1050px;
  margin-bottom: 1rem;
  color: var(--main-color);
  font-size: 0.9em;
  margin: 0 auto;
  width: 100%;
}

.order-history-table th,
.order-history-table tr {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid var(--line-color);
}

.order-history-table td {
  padding: 0.75rem;
  vertical-align: top;
}

.order-history-table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid var(--line-color);
  border-top: 1px solid var(--line-color);
  font-size: 1em;
}

.order-history-table tbody + tbody {
  border-top: 2px solid var(--line-color);
}

.order-history-table-sm th,
.order-history-table-sm td {
  padding: 0.3rem;
}

.order-history-table-hover tbody tr:hover {
  color: var(--main-color);
  background-color: rgba(212, 229, 255, 0.14);
}

.multiple-items {
  list-style: none;
  padding-left: 0;
}

.ordered-items a {
  text-decoration: none;
  color: inherit;
  padding: 0;
}

.ordered-items .box-content {
  width: 100%;
}

.item li {
  list-style-type: none;
}

.item a {
  text-decoration: none;
  color: inherit;
}

.item a:hover {
  text-decoration: none;
  color: var(--bright-color);
}

.item-img img {
  width: 210px;
  height: 210px;
}
/* array of images style */

.right-arrow {
  position: absolute;
  top: 50%;
  right: 40px;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.left-arrow-iframe {
  position: absolute;
  top: 75%;
  left: 32px;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.right-arrow-iframe {
  position: absolute;
  top: 75%;
  right: 40px;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
/* end of array of images style */
.item-container {
  width: 300px;
  min-width: 300px;
  padding: 0;
  padding-left: 1%;
  text-align: center;
}

.item-head {
  font-size: 16px;
  font-weight: 900;
  color: var(--bright-color);
  margin-bottom: 16px;
}

.wishlist-items-container .parameter span {
  font-size: 12px;
}

.item-price {
  margin-top: 16px;
  font-size: 13px;
  font-weight: bold;
}

.item-qty {
  margin-top: 10px;
  display: block;
}

.item-button {
  width: 100%;
}

.item {
  padding: 8px 12px 0 12px;
  border: 1px solid var(--line-color);
}

.item-img {
  width: 100%;
  height: auto;
}

.customer-message {
  border: 1px solid var(--dark-blue-color);
  height: 100px;
  width: 90%;
}

.customer-message {
  vertical-align: middle;
  margin-left: 14px;
  color: var(--dark-blue-color);
}

.customer-message p {
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 500;
  margin-bottom: 0;
}

.center-message {
  width: 1000px;
  height: auto;
}

.center-item {
  position: absolute;
  top: 110px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.side-bar {
  text-transform: uppercase;
}

.sidebar-toggle ul li {
  list-style: none;
  line-height: 34px;
}

.sidebar-toggle span {
  text-decoration: none;
  margin-top: 5px;
  text-transform: uppercase;
  color: var(--dark-blue-color);
}

.sidebar-toggle > .navbar > .navbar-collapse {
  border: none;
}

.sidebar-toggle {
  display: none;
  margin: 0 auto;
  width: 90%;
  padding: 8px 20px;
  border: 1px solid var(--line-color);
  background-color: rgba(212, 229, 255, 0.1);
}

.contact-container,
.about-head-container,
.shows-head-container {
  padding: 0 14%;
}

.send-message h3 {
  padding-left: 15px;
}

.clearfix::before,
.clearfix::after {
  display: table;
  content: " ";
}

.diamond-search-container {
  padding-left: 2%;
  margin: 0 auto;
}

.filter {
  position: relative;
  height: 100%;
  padding: 15px 15px 0px;
  background-color: var(--bg-color);
  margin: 0 auto;
  text-align: center;
}

.filter-col {
  padding-top: 14.4px;
  text-align: center;
}

.filter-body {
  margin-top: 15px;
}

.diamond-search-container .itemName {
  font-size: 12px;
  color: inherit;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  white-space: nowrap;
  text-align: left;
  margin-left: -6px;
}

.filter-shape-item {
  width: 8%;
  background: none;
  padding: 0;
  border: none;
  text-align: center;
  font-size: 11px;
  color: var(--main-color);
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}

.filter-shape-item .shape-icon {
  display: block;
  margin: 0 auto;
  height: 22px;
  padding-bottom: 3px;
}

.filter-shape-item .shape-icon svg {
  width: 23px;
  height: 23px;
}

.filter-shape-item:not([disabled]):hover svg .st0,
.filter-shape-item.active svg .st0 {
  stroke: var(--bg-color);
}

.noUi-horizontal {
  border: 0 none;
  border-radius: 10px;
  height: 4px;
}

.noUi-base {
  width: 100%;
  height: 100%;
  background: var(--line-color);
  position: unset;
}

.noUiSlider .noUi-connect {
  background: var(--bright-color);
  box-shadow: none;
}

.noUi-handle::before,
.noUi-handle::after {
  display: none;
}

.noUiSlider .noUi-handle {
  border: none;
  height: 12px;
  width: 12px;
  margin: 2px 0 0 -10px;
  border-radius: 20px;
  box-shadow: none;
  background: var(--bright-color);
  cursor: pointer;
  right: -6px;
}

#cut_slider {
  margin-top: 8px;
}

.noUi-marker-normal,
.noUi-value {
  display: none;
}

.noUi-marker-large {
  background-color: var(--bg-color);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: 6px;
  height: 6px;
}

.visible-xs {
  display: none !important;
}

.search-noUiSlider {
  width: 98%;
}

.search-noUiSlider .slider-data {
  overflow: hidden;
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0;
  width: 100%;
}

.search-noUiSlider span {
  font-size: 11px;
}

.min_carat_display,
.max_carat_display,
.min_price_display,
.min_price_display {
  min-height: 36px;
}

.search-noUiSlider .amount-max {
  text-align: right;
}

.search-noUiSlider .amount-min,
.search-noUiSlider .amount-max {
  font-size: 11px;
  height: 24px;
  padding: 5px 4px 3px 4px;
  min-height: 28px;
  margin-bottom: -8px;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.search-noUiSlider .input-xs {
  width: 76px;
  -webkit-appearance: none;
}

.advOptions {
  margin-left: 10.4px;
  padding-top: 14.4px;
  background-color: rgba(225, 231, 237, 0.4);
  display: block;
  width: 100%;
  padding-bottom: 14.4px;
  margin-right: 14.4px;
  margin-top: 10px;
  color: var(--bright-color);
}

.advanced-options a {
  text-decoration: none;
  color: inherit;
  display: inline;
}

.advanced-options a:hover {
  color: var(--bright-color);
  cursor: pointer;
}

.advanced-options-container {
  margin-left: 14px;
  margin-top: 6px;
}

.button-reset {
  width: 100%;
  display: grid;
  margin-left: -5px;
}

.fa-bars {
  color: var(--bright-color);
}

.lab-filter,
.fluor-filter {
  margin-left: -45px;
  text-align: left;
}

.lab-filter li,
.fluor-filter li {
  list-style: none;
  display: block;
  float: left;
  border: 1px solid var(--line-color);
  border-collapse: collapse;
  padding: 2px 9px;
  margin-left: -1px;
  text-align: center;
  font-size: 11px;
  cursor: pointer;
  background: var(--bg-color);
  color: var(--main-color);
  position: relative;
  margin-bottom: 1.2rem;
}

.lab-filter li {
  padding: 2px 10.1px;
}

.lab-filter li:hover,
.fluor-filter li:hover {
  background-color: var(--bright-color);
  color: var(--bg-color);
}

.diamond-search-input,
.hasPicture-input,
.status-input {
  height: 30px !important;
  min-height: 30px !important;
  width: 100%;
}

#item-status {
  font-size: 12px;
  margin-left: -5px;
}

#item-hasPicture,
.stock-id-wrap input {
  margin-left: -5px;
}

.pl--15 {
  padding-left: -15px;
}

.ml--15 {
  margin-left: -15px;
}

.ml-20 {
  margin-left: 20px;
}

.extra-controls {
  background-color: var(--bg-color);
  margin: 0 auto;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.results-container {
  margin: 0 auto;
  border-left: 1px solid var(--line-color);
}

.filter-top {
  margin-top: -34px;
}

.shapes-center {
  margin: 0 auto;
  margin-bottom: 20px;
}

.tablist {
  width: 100%;
}

.extra-controls .catalog-view-switcher {
  height: 22px;
  padding: 0 7px;
}

.extra-controls .catalog-view-switcher span {
  display: inline-block;
  margin-right: 8px;
}

.extra-controls .catalog-view-switcher.active {
  background: var(--bright-color);
  color: var(--bg-color);
}

#pills-list-tab:hover,
#pills-visual-tab:hover {
  color: var(--bright-color);
}

.extra-controls #pills-list-tab:hover.active,
.extra-controls #pills-visual-tab:hover.active {
  background: var(--bg-color);
  color: var(--bright-color);
}

.fa-list {
  margin-right: 7px;
}

.dropdowns {
  width: 100%;
  display: inline-block;
}

#items-per-page select,
#sort select {
  font-size: 12px;
  outline: 0;
  box-shadow: none;
  border-radius: 0;
  padding: 5px 20px;
  float: right;
}

#sort {
  float: right;
  margin-right: 11.8%;
}

#items-per-page {
  float: right;
  margin-right: 10px;
}

#items-per-page,
#sort {
  display: inline-block;
}

#items-per-page {
  margin-left: 10px;
}

.custom-select:focus {
  border-color: var(--bright-color);
}

#pills-tabContent {
  margin: 0 auto;
}

.main-product-buttons .col-md-6 {
  padding: 0;
}

.main-product-buttons .col-md-6 button {
  font-size: 11px;
}

tr .hiddenRow {
  padding: 0;
  min-width: 991.98px;
}

#display-search-table_wrapper {
  width: 104%;
}

#display-search-table {
  min-width: 991.98px;
  border-radius: 0;
  font-size: 12px;
  color: var(--main-color);
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(212, 229, 255, 0.1);
}

.table-striped tbody tr td.status {
  vertical-align: middle;
}

.table-striped tbody tr td div.status-wrap {
  display: flex;
  justify-content: center;
}

.table-striped tbody tr td div.available {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: green;
}

.card-link-container div.available {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: green;
}

.table-striped tbody tr td div.memo {
  width: 18px;
  height: 18px;
  margin-top: 4px;
  border-radius: 50%;
  background-color: red;
}

.card-link-container div.memo {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: red;
}

.table-striped tbody tr td div.hold {
  width: 18px;
  height: 18px;
  margin-top: 4px;
  border-radius: 50%;
  background-color: rgb(236, 236, 0);
}

.card-link-container div.hold {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: rgb(236, 236, 0);
}

.table-striped tbody tr td div.suspended {
  width: 18px;
  height: 18px;
  margin-top: 4px;
  border-radius: 50%;
  background-color: lightgrey;
}

.card-link-container div.suspended {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: lightgrey;
}

#display-search-table tr td button {
  margin: 0;
  font-size: 8px;
  min-width: 120px;
}

div.dataTables_wrapper div.dataTables_length select {
  border-radius: 0 !important;
}

#display-search-table_filter,
#display-search-table_length {
  display: none;
}

#display-search-table thead > tr > th {
  color: var(--bright-color);
  font-size: 13px;
}

#display-search-table thead > tr > th .stockId {
  width: 100px;
}

#display-search-table_paginate .pagination .page-item.active .page-link,
.pagination .page-item.active .page-link {
  background-color: var(--bright-color);
  border-color: var(--bright-color);
}

.active-button {
  background-color: var(--bright-color);
  border-color: var(--bright-color);
  color: #fff;
  text-transform: uppercase;
  padding: 3px 7px;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
}

.inactive-button {
  opacity: 0.4;
  background-color: var(--main-color);
  border-color: var(--bright-color);
  color: #fff;
  text-transform: uppercase;
  padding: 3px 7px;
  font-size: 0.8rem;
  font-weight: bold;
}

.accordian-body {
  background-color: rgba(217, 225, 232, 0.2);
}

.image-container img {
  width: 178px;
}

.main-buttons .btn-custom-outline {
  background-color: transparent;
}

.image-container,
.button-container {
  margin-top: auto;
  margin-bottom: auto;
}

#advSettings {
  width: 95%;
}

.advanced-details {
  font-size: 13px;
  padding-top: 10px;
  vertical-align: middle;
}

.advanced-details span {
  padding-left: 7%;
  text-align: center;
}

.icon-buttons {
  font-size: 18px;
  display: inline;
}

.icon-buttons a {
  text-decoration: none;
  color: inherit;
}

.btn-name {
  font-size: 11px;
}

.whatsappShare,
.addToWishList,
.holdBtn {
  padding: 0 11%;
  width: 100%;
}

.whatsappShare:hover,
.addToWishList:hover,
.holdBtn:hover {
  color: var(--bright-color);
}

.about-container ul {
  list-style: none;
  padding: 0;
}

.about-container ul li {
  display: inline-block;
  padding-right: 15px;
}

.about-container ul li i {
  font-size: 8px;
  height: 10px;
  vertical-align: middle;
}

.about-container img {
  width: 500px;
  height: auto;
}

.about-container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background-color: rgba(212, 229, 255, 0.1);
  color: var(--main-color);
}

.about-container img {
  width: 100%;
  padding: 0;
}

.about-container .col-lg-6 {
  padding: 0;
}

.about-container p {
  padding: 0 5%;
  width: 100%;
}

.shows-container {
  padding: 0 8%;
  margin: 0 auto;
  width: 100%;
}

.show-card {
  width: 100%;
}

.show-card {
  text-align: center;
  border: 1px solid var(--line-color);
  border-radius: 0;
}

.list-group-item {
  border: 1px solid var(--line-color);
  margin-left: 5px;
}

.show-card .card-title {
  font-family: "Oswald", sans-serif;
  color: var(--bright-color);
  font-size: 26px;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: -4px;
}

.show-card i {
  color: var(--main-color);
}

.card-body button {
  width: 90%;
  font-size: 12px;
}

.list-group-flush {
  font-size: 12px;
}

.column {
  float: left;
  width: 20%;
  padding: 0 10px;
  margin-bottom: 30px;
  min-width: 310px;
}

.column-product {
  float: left;
  width: 30%;
  padding: 0 10px;
  margin-bottom: 30px;
  min-width: 370px;
}

.card-img-container img.custom-card-img-top {
  width: 100%;
  height: auto; 
  object-fit: cover;
}

.product-card {
  text-align: center;
  border-radius: 0;
}

.product-card-title {
  font-size: 14px;
  color: var(--bright-color);
  font-weight: bold;
}

.product-details {
  margin: 0 auto;
  text-align: center;
  font-size: 13px;
}

.product-icon-buttons a {
  text-decoration: none;
  color: inherit;
}

.product-icon-buttons a:hover > .fa-whatsapp,
.product-icon-buttons a:hover > .fa-heart,
.product-icon-buttons a:hover > .fa-envelope {
  color: var(--bright-color);
}

.colors a:hover {
  color: var(--bright-color);
  border: 1px solid var(--bright-color);
}

.number-input {
  width: 80px;
}

.buttons-cart {
  padding: 0;
  margin-left: 0;
}

#product-table a {
  text-decoration: none;
  color: inherit;
}

#product-table .product-heading {
  font-size: 14px;
}

.shoppingcart-total {
  border-right: 1px solid var(--line-color);
  padding-right: 20px;
}

.diamond-heading h1 {
  font-size: 24px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 1px;
  color: var(--dark-blue-color);
}

.diamond-price {
  font-size: 16px;
}

.sub-buttons {
  max-width: 90%;
  border-top: 1px solid var(--line-color);
  border-bottom: 1px solid var(--line-color);
  padding-top: 10px;
}

.diamond-main-buttons {
  max-width: 68%;
}

.whatsappShare-inline,
.addToWishList-inline,
.holdBtn-inline {
  padding: 0 8%;
  display: inline-block;
  padding-bottom: 10px;
}

.whatsappShare-inline {
  margin-left: 8%;
}

.whatsappShare-inline .btn-name,
.addToWishList-inline .btn-name,
.holdBtn-inline .btn-name {
  display: inline;
}

.sub-buttons a {
  text-decoration: none;
  color: inherit;
  display: inline;
}

.sub-buttons a i {
  display: inline;
  text-align: center;
}

.product-details-list h3 {
  color: var(--dark-blue-color);
}

.diamond-main-buttons {
  padding: 0;
}

.diamond-main-buttons .col-md-7 button,
.diamond-main-buttons .col-md-5 button {
  width: 100%;
}

.diamond-main-buttons .col-md-7,
.diamond-main-buttons .col-md-5 {
  padding-left: 0;
}

.diamond-view-container {
  padding: 0;
}

.product-details-list {
  background-color: rgba(217, 225, 232, 0.3);
  padding: 4% 10%;
  margin-top: 20px;
}

.product-details-list > h3 {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--line-color);
}

.position-relative {
  position: relative;
}

.diamond-details {
  padding-left: 20px;
}

.diamond-details dl dt {
  float: left;
  font-size: 13px;
  margin-right: 4px;
}

.diamond-details dl dd {
  padding-left: 3px;
  font-size: 12px;
}

.diamond-details dt {
  color: var(--bright-color);
}

.diamond-details,
.diamond-certification {
  margin-top: 14px;
}

.diamond-certification dl dt {
  float: left;
  margin-right: 4px;
}

.diamond-certification a {
  text-decoration: none;
  color: var(--bright-color);
}

.diamond-certification a:hover {
  text-decoration: underline;
}

#sort {
  min-width: 150px;
}

.no_sort::before {
  display: none !important;
}

.no_sort::after {
  display: none !important;
}

#pills-tab .nav-item {
  padding-left: 32px;
}

.yellow-gem,
.orange-gem,
.red-gem,
.purple-gem,
.pink-gem,
.green-gem,
.blue-gem,
.violet-gem,
.brown-gem,
.gray-gem,
.black-gem,
.chameleon-gem,
.white-gem {
  display: inline-block;
  padding-left: 3%;
  text-align: center;
}

.yellow-gem img,
.orange-gem img,
.red-gem img,
.purple-gem img,
.pink-gem img,
.green-gem img,
.blue-gem img,
.violet-gem img,
.brown-gem img,
.gray-gem img,
.black-gem img,
.chameleon-gem img,
.white-gem img {
  display: inline-block;
  width: 20px;
  vertical-align: middle;
}

.yellow-gem a,
.orange-gem a,
.red-gem a,
.purple-gem a,
.pink-gem a,
.green-gem a,
.blue-gem a,
.violet-gem a,
.brown-gem a,
.gray-gem a,
.black-gem a,
.chameleon-gem a,
.white-gem a {
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
  font-size: 11px;
}

.yellow-gem a {
  width: 36px;
}

.violet-gem a,
.white-gem a {
  width: 32px;
}

.orange-gem a {
  width: 41px;
}

.red-gem a {
  width: 21px;
}

.purple-gem a,
.green-gem a,
.brown-gem a {
  width: 36px;
}

.pink-gem a,
.blue-gem a {
  width: 25px;
}

.gray-gem a {
  width: 26px;
}

.black-gem a {
  width: 30px;
}

.chameleon-gem a {
  width: 63px;
}

.color_diamonds_container {
  margin-top: -34px;
  margin-left: -36px;
}

.detail-property .diamond-heading {
  border-bottom: 1px solid var(--line-color);
  width: 90%;
}

.addToCart {
  padding: 0;
}

.addToCart button {
  width: 90%;
}

.no_sort {
  padding-right: 5%;
}

.itemShape {
  min-width: 50px;
}

.stockID,
.itemSymmetry,
.itemMeasurements,
.itemDetails {
  min-width: 60px;
}

.itemCarat,
.itemCut,
.itemClarity,
.itemPolish,
.itemFluor,
.itemTable,
.itemDepth,
.itemPrice,
.itemPPC {
  min-width: 35px;
}

.image-preview {
  max-width: 800px;
  padding-left: 7%;
  padding-right: 4%;
}

.gallery .images .image {
  position: relative;
  width: 100%;
  padding-bottom: 60%;
  border-radius: 0px;
  box-shadow: 1px 1px 5px rgba(216, 214, 224, 0.9);
}

.gallery .images .image .content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

.gallery .thumbs .thumb.active {
  border-color: var(--line-color);
}

/* ====================== CUSTOM CATEGORY PAGE ==================== */

/* CUSTOM UTILS */
.custom-divider {
  border-bottom: 1px solid var(--line-color);
}

.container-fluid.custom-container {
  margin: 0 10rem;
}

/* FILTERS */
.select-form-filter {
  display: grid;
  grid-template-columns: 70px auto;
  grid-template-rows: 28px;
  gap: 10px;
  align-items: center;
}

.select-form-filter label {
  margin: 0;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.btn-toolbar {
  display: grid;
}

.select-form-filter .btn-toolbar button {
  border-radius: 0;
  box-shadow: none;
  border-color: var(--line-color);
  height: 2.2rem;
  background-color: #fff;
  margin-left: -1px;
  color: #777;
  padding: 0;
  font-size: 0.7rem;
  text-transform: uppercase;
}

.select-form-filter .btn-toolbar .fancy-intensive-text-size {
  text-transform: none;
}

.select-form-filter .btn-toolbar button.active + button {
  border-left-color: var(--bright-color);
}

.select-form-filter .btn-toolbar button:first-of-type {
  margin-left: unset;
}

.select-form-filter .btn-toolbar button.active {
  color: var(--bg-color) !important;
  border-color: var(--bright-color) !important;
  background-color: var(--bright-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 6px;
}

.select-form-filter .btn-toolbar button {
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: center;
  text-transform: uppercase;
}

.select-form-filter .btn-toolbar button svg {
  height: 1rem;
  width: 1rem;
}

.select-form-filter .btn-toolbar button:hover > svg > g > *,
.select-form-filter .btn-toolbar button:last-of-type:hover > svg > *,
.select-form-filter .btn-toolbar button.active:last-of-type > svg > g *,
.select-form-filter .btn-toolbar button.active > svg > g > *,
.select-form-filter .btn-toolbar button.active > svg > * {
  stroke: var(--main-color);
}

.filter-slider.noUi-horizontal {
  margin: 0 10px;
  border: 0 none;
  border-radius: 10px;
  height: 4px;
}

.filter-slider .noUi-base {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: var(--line-color);
  position: unset;
}

.filter-slider .noUi-connect {
  background: var(--bright-color);
  box-shadow: none;
}

.filter-slider .noUi-handle::before,
.filter-slider .noUi-handle::after {
  display: none;
}

.filter-slider .noUi-handle.noUi-handle-lower,
.filter-slider .noUi-handle.noUi-handle-upper {
  border: none;
  height: 12px;
  width: 12px;
  margin: 2px 0 0 -10px;
  border-radius: 20px;
  box-shadow: none;
  background: var(--bright-color);
  cursor: pointer;
  right: -6px;
}

.slider-header {
  display: grid;
  grid-template-columns: 70px auto auto;
  gap: 10px;
}

.slider-header span {
  /* align-self: center; */
  padding-top: 5px;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.slider-header input.form-control {
  align-self: center;
  padding: 0 5px;
  min-height: 0;
  height: 30px;
  /* margin: 15px 0; */
}

.slider-header input[type="number"]::-webkit-inner-spin-button,
.slider-header input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.slider-header input[type="number"] {
  -moz-appearance: textfield;
}

.no-slider span {
  align-self: center;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.no-slider input.form-control {
  align-self: center;
  padding: 0 5px;
  min-height: 0;
  height: 28px;
  margin: 0;
}

/* SORT */
.sort-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 3px 7px;
  border-color: var(--line-color);
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
}

.sort-container:hover {
  color: var(--bright-color);
  border-color: var(--bright-color);
}

.sort-container.active {
  background-color: var(--bright-color);
  color: #fff;
  border-color: var(--bright-color);
}

.sort-container.active:hover {
  color: #fff;
}

.sort-container i {
  align-self: center;
  justify-self: end;
}

.sort-and-page .dropdown-toggle {
  box-shadow: none;
  background-color: #fff;
  border: 1px solid var(--line-color);
  color: var(--main-color);
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
}

.sort-and-page .dropdown-toggle:active {
  background-color: #fff !important;
  color: var(--main-color) !important;
  border-color: var(--bright-color) !important;
}

.sort-and-page.show .dropdown-toggle {
  background-color: #fff;
  box-shadow: none !important;
  border-color: var(--bright-color);
  color: var(--main-color);
}

.sort-and-page.show .dropdown-menu.show {
  border-top: 1px solid var(--line-color);
  padding: 0;
}

.sort-and-page.show .dropdown-menu.show .dropdown-item {
  color: var(--main-color);
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
}

.sort-and-page.show .dropdown-menu.show .dropdown-item:hover,
.sort-and-page.show .dropdown-menu.show .dropdown-item.active {
  background-color: var(--bright-color);
  color: #fff;
}

/* CARD GRID */
.custom-card {
  display: grid;
  transition: 0.2s ease-in;
}
.custom-card.card-selected {
  border-color: var(--bright-color);
  background-color: var(--light-grey-color);
}

.card-body.custom-card-body {
  display: grid;
}

.card-body.custom-card-body .card-title {
  color: var(--bright-color);
  font-size: 1rem;
  font-weight: bold;
}

.card-body.custom-card-body .card-subtitle {
  font-size: 0.9rem;
}

.card-body.custom-card-body .card-text {
  font-size: 0.9rem;
  font-weight: bold;
}

.card-link-container {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  padding: 10px 0;
}

.card-link-container:hover {
  color: var(--bright-color);
  cursor: pointer;
}

.card-body.custom-card-body .custom-card-button-container {
  align-self: end;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 42px;
  gap: 15px;
  align-items: center;
}

.card-body.custom-card-body .custom-card-button-container .btn {
  height: 100%;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  border-radius: 0;
  border: 1px solid;
  font-size: 0.8rem;
  font-weight: bold;
}

.card-body.custom-card-body .custom-card-button-container .btn.details {
  border-color: var(--dark-blue-color);
  color: var(--dark-blue-color);
  background-color: transparent;
}

.card-body.custom-card-body .custom-card-button-container .btn.details:hover {
  border-color: var(--bright-color);
  color: var(--bright-color);
}

.card-body.custom-card-body .custom-card-button-container .btn.add-to-cart {
  border-color: var(--dark-blue-color);
  background-color: var(--dark-blue-color);
  color: #fff;
}

.card-body.custom-card-body .custom-card-button-container .btn.add-to-cart:hover {
  border-color: var(--bright-color);
  background-color: var(--bright-color);
}

.card-body.custom-card-body .custom-card-button-container .btn.add-to-cart.added {
  border-color: var(--bright-color);
  background-color: var(--bright-color);
}

.card-body.custom-card-body .custom-card-button-container .btn.add-to-cart.added:hover {
  border-color: var(--dark-blue-color);
  background-color: var(--dark-blue-color);
}

.card-subtitle ul.card-subtitle-list {
  padding: 0;
}

.card-subtitle ul.card-subtitle-list li.card-subtitle-list-item {
  display: inline-block;
}

.card-subtitle ul.card-subtitle-list li.card-subtitle-list-item:before {
  content: "•";
  margin: 0 5px;
}

.card-subtitle ul.card-subtitle-list li.card-subtitle-list-item:first-of-type:before {
  content: "";
}

/* ADMIN MAINTANANCE */
.maintenance-page {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.maintenance-page p {
  border: 1px solid var(--dark-blue-color);
  color: var(--dark-blue-color);
  font-weight: 500;
  letter-spacing: 1.3px;
  padding: 50px;
  text-align: center;
  text-transform: uppercase;
  width: 90%;
}

/* LIST VIEW  CATEGORY */
.table.table-bordered.category-list-view-table thead tr th {
  color: var(--bright-color);
  text-transform: capitalize;
  white-space: nowrap;
  text-align: center;
  padding: 0.1rem;
  font-size: 0.9rem;
}

.table.table-bordered.category-list-view-table thead tr th:hover {
  cursor: pointer;
}

.table.table-bordered.category-list-view-table thead tr th i {
  font-size: 12px;
  display: none;
  color: var(--line-color);
}

.table.table-bordered.category-list-view-table thead tr th i.active {
  color: var(--bright-color);
  display: inline-block;
}

table.table-bordered.category-list-view-table tbody tr td {
  white-space: nowrap;
  color: #777;
  text-align: center;
  padding: 0.3rem;
  font-size: 0.9rem;
}

table.table-bordered.category-list-view-table tbody tr td button {
  color: var(--dark-blue-color);
  border: 1px solid var(--dark-blue-color);
  font-family: var(--font-family);
  letter-spacing: 1px;
  background-color: inherit;
  font-size: 8px;
  width: 70px;
  font-weight: bold;
  text-transform: uppercase;
}

table.table-bordered.category-list-view-table tbody tr td button:hover {
  color: var(--bright-color);
  border-color: var(--bright-color);
}

.position-relative .popupContainer {
  white-space: normal !important;
  width: 260px;
}
.popupContainer span {
  max-width: 245px !important;
  text-align: center;
  font-size: "0.9rem";
}

.popupButton {
  color: #777 !important;
  border: 1px solid var(--dark-blue-color);
  font-family: var(--font-family);
  letter-spacing: 1px;
  background-color: inherit;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  flex-grow: 1;
}

.popupButton:hover {
  color: var(--bright-color) !important;
  border-color: var(--bright-color);
}

.table-responsive .table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.wrap-buttons-list-view {
  flex-wrap: nowrap;
}

/* PAGINATION */
.pagination .page-link {
  color: var(--main-color);
  border: 1px solid var(--line-color);
  border-radius: 0 !important;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.pagination .page-link:hover {
  border-color: var(--bright-color);
  color: var(--bright-color);
  cursor: pointer;
  background-color: #fff;
}

.pagination .page-item.active .page-link:hover {
  color: #fff;
}

.pagination .page-item.disabled .page-link {
  color: var(--line-color);
  border-color: var(--line-color);
}

.pagination .page-link:focus {
  box-shadow: none;
}

/* SINGLE ITEM PAGE */

/* .single-item-image {
  box-shadow: 1px 1px 5px rgba(216, 214, 224, 0.9);
  width: 100%;
} */

.single-item-title-container {
  font-family: var(--secondary-font-family);
  letter-spacing: 1px;
  color: var(--dark-blue-color);
}

.single-item-title-container .single-item-title {
  font-weight: 800;
  font-size: 2rem;
  text-align: center;
}

.single-item-title-container .single-item-secondary-title {
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
}

.single-item-price p {
  font-size: 1.2rem;
  color: var(--main-color);
  margin: 1rem 0;
}

.single-item-button button.single-item-add {
  background-color: var(--dark-blue-color) !important;
  text-transform: uppercase;
  font-size: 0.8rem !important;
  height: 3rem;
  letter-spacing: 1px;
  font-weight: bold;
  font-family: var(--font-family);
  border-radius: 0;
  color: #fff !important;
}

.single-item-button button.single-item-add:hover {
  background-color: var(--bright-color) !important;
}

.single-item-button button.single-item-add.added {
  background-color: var(--bright-color) !important;
}

.single-item-button button.single-item-add.added:hover {
  background-color: var(--dark-blue-color) !important;
}

.single-item-icon-container {
  font-size: 0.9rem;
}

.single-item-icon-container i {
  padding-right: 5px;
}

.single-item-icon-container:hover {
  color: var(--bright-color);
  cursor: pointer;
}

.single-item-grid-container {
  display: grid;
  padding: 20px 21px 0 55px;
  grid-auto-flow: column;
}
.single-item-iframe-cert {
  width: 100%;
  height: 900px;
}

.single-item-image-select {
  background-size: cover;
  justify-self: center;
  align-self: center;
  white-space: nowrap;
  height: 90px;
  width: 90px;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.single-item-image-select:hover {
  cursor: pointer;
  border: 3px solid var(--line-color) !important;
}

.single-item-image-select.active {
  border: 3px solid var(--line-color) !important;
  pointer-events: none;
}
.single-item-image-select:after,
.single-item-image:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.single-item-image-select.active:after {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: var(--line-color);
  border-width: 8px;
  margin-left: -8px;
}

.single-item-image-select.active:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: var(--line-color);
  border-width: 14px;
  margin-left: -14px;
}

.single-item-details-container .single-item-description-title {
  font-family: var(--font-family);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  color: var(--dark-blue-color);
}

.single-item-description-item,
.single-item-description-item-right {
  white-space: nowrap;
}

.single-item-description-item .description-primary {
  display: inline-block;
  color: var(--bright-color);
  font-size: 0.95rem;
  font-weight: bold;
  text-transform: capitalize;
  width: 70px;
}
.single-item-description-item .description-position-fix {
  width: 150px;
  margin-top: 15px;
}
.single-item-description-item-right .description-primary {
  display: inline-block;
  color: var(--bright-color);
  font-size: 0.95rem;
  font-weight: bold;
  text-transform: capitalize;
  width: 100px;
}

.single-item-description-item .description-secondary {
  color: var(--main-color);
  font-size: 0.75rem;
  font-weight: 200;
  padding: 0 5px 0 5px;
}
.single-item-description-item-right .description-secondary {
  color: var(--main-color);
  font-size: 0.75rem;
  font-weight: 200;
  padding: 0 5px 0 5px;
}

.single-item-pdf-wrapper {
  padding: 0;
}
.single-container-top {
  display: flex;
}

.single-container-bottom {
  display: none;
}

.single-item-pdf-canvas {
  width: 100%;
}

.home-page-user-title {
  font-family: var(--secondary-font-family);
  color: var(--dark-blue-color);
  font-weight: 800;
}

/*  CUSTOM HOME WHEN LOGGED */
.grid-container {
  display: grid;
  grid-template-areas:
    "cart product"
    "cart product"
    "order account"
    "order account"
    "memo .";
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.grid-container .grid-item-cart {
  grid-area: cart;
  box-shadow: 1px 1px 5px rgba(216, 214, 224, 0.9);
}
.grid-container .grid-item-memo {
  grid-area: memo;
  box-shadow: 1px 1px 5px rgba(216, 214, 224, 0.9);
}

.grid-container .grid-item-product {
  grid-area: product;
  box-shadow: 1px 1px 5px rgba(216, 214, 224, 0.9);
}

.grid-container .grid-item-account {
  grid-area: account;
  box-shadow: 1px 1px 5px rgba(216, 214, 224, 0.9);
}

.grid-container .grid-item-order {
  grid-area: order;
  box-shadow: 1px 1px 5px rgba(216, 214, 224, 0.9);
}

.grid-container .grid-item-cart .home-cart-title,
.grid-container .grid-item-memo .home-memo-title,
.grid-container .grid-item-order .home-order-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--bright-color);
  padding-bottom: 1.3rem;
  font-size: 1.5rem;
  font-family: var(--secondary-font-family);
  font-weight: bold;
  border-bottom: 1px solid var(--line-color);
}

.grid-container .grid-item-cart .home-cart-title span,
.grid-container .grid-item-memo .home-memo-title span,
.grid-container .grid-item-order .home-order-title span {
  font-weight: bold;
  padding-right: 20px;
}

.grid-container .grid-item-title .home-cart-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--bright-color);
  font-size: 1.5rem;
  padding: 1rem 0;
}

.grid-container .grid-item-cart .home-cart-content span,
.grid-container .grid-item-memo .home-memo-content span,
.grid-container .grid-item-order .home-order-content span {
  font-size: 1rem;
  letter-spacing: 1px;
  color: var(--dark-blue-color);
  font-weight: 800;
}

.grid-container .grid-item-cart .home-cart-content ol,
.grid-container .grid-item-memo .home-memo-content ol,
.grid-container .grid-item-order .home-order-content ol {
  padding-left: 1rem;
}

.grid-container .grid-item-cart .home-cart-content ol li,
.grid-container .grid-item-memo .home-memo-content ol li,
.grid-container .grid-item-order .home-order-content ol li {
  border-bottom: 1px solid var(--line-color);
  font-size: 1rem;
  font-weight: bold;
  color: var(--main-color);
  margin: 1rem 0;
  padding: 1rem 0;
}

.grid-container .grid-item-cart .home-cart-content ol li .item-content,
.grid-container .grid-item-memo .home-memo-content ol li .item-content,
.grid-container .grid-item-order .home-order-content ol li .item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid-container .grid-item-cart .home-cart-content ol li .item-content span,
.grid-container .grid-item-memo .home-memo-content ol li .item-content span,
.grid-container .grid-item-order .home-order-content ol li .item-content span {
  font-family: var(--font-family);
  color: var(--bright-color);
  font-size: 1rem;
}

.grid-container .grid-item-cart .home-cart-content ol li .item-content button,
.grid-container .grid-item-memo .home-memo-content ol li .item-content button,
.grid-container .grid-item-order .home-order-content ol li .item-content button {
  border: 1px solid var(--dark-blue-color);
  border-radius: 0;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--dark-blue-color);
  text-transform: uppercase;
}

.grid-container .grid-item-cart .home-cart-content ol li .item-content button:hover,
.grid-container .grid-item-memo .home-memo-content ol li .item-content button:hover,
.grid-container .grid-item-order .home-order-content ol li .item-content button:hover {
  border-color: var(--bright-color);
  color: var(--bright-color);
}

.grid-container .grid-item-cart .show-all-container,
.grid-container .grid-item-memo .show-all-container,
.grid-container .grid-item-order .show-all-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-container .grid-item-cart .show-all-container span,
.grid-container .grid-item-memo .show-all-container span,
.grid-container .grid-item-order .show-all-container span {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--dark-blue-color);
}

.grid-container .grid-item-cart .show-all-container span:hover,
.grid-container .grid-item-memo .show-all-container span:hover,
.grid-container .grid-item-order .show-all-container span:hover {
  color: var(--bright-color);
  cursor: pointer;
}

.grid-container .grid-item-product h3 {
  color: var(--bright-color);
  text-align: center;
  font-family: var(--secondary-font-family);
  font-weight: bold;
}

.grid-container .grid-item-product span {
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: bold;
  color: var(--dark-blue-color);
}

.grid-container .grid-item-account .gird-account-title {
  font-family: var(--secondary-font-family);
  font-weight: 800;
  color: var(--bright-color);
  font-size: 1.8rem;
}

.grid-container .grid-item-account .grid-account-info {
  color: var(--main-color);
  font-size: 1rem;
}

.grid-container .grid-item-account .grid-account-info .contact-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-container .grid-item-account .grid-account-info .contact-holder * {
  margin-left: 5px;
}

.grid-container .grid-item-account .grid-account-info .contact-holder *:nth-child(3) {
  color: var(--bright-color);
  font-weight: bold;
}

.cart-item-grid-container {
  display: grid;
  grid-template-areas:
    "img header"
    "img description";
  grid-template-columns: 70px 1fr;
  gap: 10px;
}

.cart-item-grid-container .cart-item-grid-image {
  grid-area: img;
}

.cart-item-grid-container .cart-item-grid-image img {
  width: 100%;
  height: 70px;
}

.cart-item-grid-container .cart-item-grid-header {
  grid-area: header;
  font-size: 1rem;
  color: var(--dark-blue-color);
  font-weight: 600;
}

.cart-item-grid-container .cart-item-grid-header span:hover {
  border-bottom: 1px solid var(--dark-blue-color);
  cursor: pointer;
}

.cart-item-grid-container .cart-item-grid-description {
  grid-area: description;
}

.cart-item-grid-container .cart-item-grid-description span {
  display: inline-block;
  color: var(--main-color);
  font-size: 0.9rem;
}

.cart-item-grid-container .cart-item-grid-description span:before {
  content: "•";
  margin: 0 5px;
}

.cart-item-grid-container .cart-item-grid-description span:first-of-type:before {
  content: "";
  margin: 0;
}

.cart-item-melee-grid-container .cart-item-melee-header {
  font-size: 1rem;
  color: var(--dark-blue-color);
  font-weight: 600;
}

.cart-item-melee-grid-container .cart-item-melee-description span {
  display: inline-block;
  color: var(--main-color);
  font-size: 0.9rem;
}

.cart-item-melee-grid-container .cart-item-melee-description span:before {
  content: "•";
  margin: 0 5px;
}

.cart-table-title {
  color: var(--dark-blue-color);
  font-family: var(--secondary-font-family);
}

.cart-table .cart-table-header,
.order-table .order-table-header {
  color: var(--bright-color);
}

.cart-table .cart-table-price {
  font-size: 0.9rem;
  color: var(--main-color);
}

.cart-table button.btn.cart-table-btn-remove {
  color: red;
  border: 1px solid red;
  border-radius: 0;
}

.cart-table button.btn.cart-table-btn-remove:hover {
  background-color: red;
  color: #fff;
}

.cart-table .cart-table-last-row {
  border-bottom: 1px solid #dee2e6;
  font-size: 0.9rem;
  color: var(--main-color);
}

.cart-table .cart-table-last-row .cart-total-price {
  font-weight: bold;
  border-left: 1px solid #dee2e6;
}

button.btn.cart-table-go-back {
  border: 1px solid var(--dark-blue-color);
  border-radius: 0;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--dark-blue-color);
  text-transform: uppercase;
}

button.btn.cart-table-go-back:hover {
  border-color: var(--bright-color);
  color: var(--bright-color);
}

button.btn.cart-table-checkout {
  background-color: var(--dark-blue-color);
  border-radius: 0;
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase;
}

button.btn.cart-table-checkout:hover {
  background-color: var(--bright-color);
}

.order-created-container .order-created-sub-header {
  color: var(--bright-color);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.order-created-container .order-created-action-buttons button.btn.view-order,
.order-created-container .order-created-action-buttons button.btn.back-button {
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 1px;
  width: 100%;
  text-transform: uppercase;
}

.order-created-container .order-created-action-buttons button.btn.view-order {
  background-color: var(--dark-blue-color);
  color: #fff;
  border-radius: 0;
}

.order-created-container .order-created-action-buttons button.btn.view-order:hover {
  background-color: var(--bright-color);
}

.order-created-container .order-created-action-buttons button.btn.back-button {
  border: 1px solid var(--dark-blue-color);
  border-radius: 0;
  color: var(--dark-blue-color);
}

.order-created-container .order-created-action-buttons button.btn.back-button:hover {
  border-color: var(--bright-color);
  color: var(--bright-color);
}

.order-table tbody tr td {
  color: var(--main-color);
  font-size: 0.9rem;
}

.order-table button.btn.order-button-details {
  background-color: var(--dark-blue-color);
  border-radius: 0;
  color: #fff;
}

.order-table button.btn.order-button-details:hover {
  background-color: var(--bright-color);
  cursor: pointer;
}

.order-address-container span:first-of-type {
  color: var(--bright-color);
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 2rem;
}

.single-order-table {
  border-bottom: 1px solid var(--line-color);
}

.order-single-table-container .order-table-title {
  border-top: 1px solid var(--line-color);
  font-size: 1rem;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

.order-single-table-container button.btn.single-order-back-button {
  border: 1px solid var(--dark-blue-color);
  border-radius: 0;
  color: var(--dark-blue-color);
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 1px;
}
.order-single-table-container button.btn.single-order-back-button:hover {
  color: var(--bright-color);
  border-color: var(--bright-color);
}
.diamond-fancy-image {
  width: auto;
  height: auto;
}

.list-view-container {
  padding-left: 120px !important;
  padding-right: 120px !important;
}
.list-view-container-memo {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Modal 3d view */

.modal-3d-view-container {
  width: 500px;
  height: 530px;
  background-color: #fff;
  border-radius: 12px;
  padding-bottom: 8px;
  border: 4px solid var(--main-color);
}

.modal-3d-view-container-cert {
  width: 1080px;
  height: 650px;
  background-color: #323639;
  border-radius: 12px;
  padding-bottom: 8px;
  border: 4px solid var(--main-color);
}

.modal-3d-view-icon-container {
  right: -20px;
  top: -21px;
  z-index: 3;
  background-color: #fff;
  border-radius: 100%;
  width: 38px;
  height: 38px;
  cursor: pointer;
}
.embed-responsive-item.pt-2.modal-iframe {
  align-self: center;
  margin-top: 8px;
  border-radius: 12px;
  display: none;
}
.embed-responsive-item.pt-2.modal-iframe2 {
  align-self: center;
  margin-top: 8px;
  border-radius: 12px;
  display: block;
}
.embed-responsive-item.pt-2.modal-iframe.active {
  align-self: center;
  margin-top: 8px;
  border-radius: 12px;
  display: block;
}
.modal-3d-view-container .position-absolute .fa-times-circle:hover {
  color: var(--bright-color);
  transition: 0.1s ease-in;
}

/* Icons */
.eye-icon {
  padding: 10px;
  color: var(--line-color);
  transform: scale(0.8);
  right: -1.3px;
  top: -3px;
  transition: 0.2s ease-in;
  cursor: pointer;
}
.eye-icon-slash {
  padding: 10px;
  color: var(--line-color);
  transform: scale(0.8);
  right: -2.91px;
  top: -3.5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.eye-icon:hover {
  color: var(--bright-color);
}
.eye-icon-slash:hover {
  color: var(--bright-color);
}

/*My Account Page Side Navigation */

.foldable-menu {
  width: 16.5%;
  transition: 0.3s ease-out;
}

.foldable-menu.foldSideMenu {
  width: 1%;
}

.fas.fa-chevron-circle-left.position-absolute {
  top: 50px;
  right: -15px;
  z-index: 10;
  background-color: white;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-out;
  transform: scale(0);
}

.fas.fa-chevron-circle-left.position-absolute.foldSideMenu {
  color: var(--dark-blue-color);
  visibility: visible;
  opacity: 1;
  animation-name: rotate;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
}

.foldable-menu:hover .fas.fa-chevron-circle-left.position-absolute {
  color: var(--dark-blue-color);
  visibility: visible;
  opacity: 1;
  transition: 0.1s ease-out;
  transform: scale(1);
}

#sideMenuSlide {
  white-space: nowrap;
  opacity: 1;
}

.nav-sidenav {
  animation-name: textTranslate1;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.foldSideMenu.nav-bar {
  animation-name: textTranslate;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  white-space: nowrap;
}

.categorySpan {
  animation-name: textTranslate1;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.foldSideMenu.categorySpan {
  animation-name: textTranslate;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  white-space: nowrap;
}

.settingsContainer {
  flex: 0 0 83.33%;
  width: 83.33%;
  transition: 0.3s ease-out;
}
.settingsContainer.foldedSideMenu {
  flex: 0 0 99%;
  width: 99%;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes textTranslate {
  from {
    transform: translateX(0px);
    opacity: 0.7;
  }
  to {
    transform: translateX(-250px);
    opacity: 0;
  }
}
@keyframes textTranslate1 {
  0% {
    transform: translateX(-250px);
    opacity: 0;
  }
  50% {
    transform: translateX(-250px);
    opacity: 0;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

/* Special Shape DropDown */
.special-shape-dropdown-hidden {
  position: absolute;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  width: 150px;
  background: white;
  top: 30px;
  right: 0;
  z-index: 10;
  overflow-y: scroll;
  border: 1px solid var(--line-color);
}
.special-shape-dropdown-visible {
  text-align: center;
  position: absolute;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: visible;
  opacity: 1;
  width: 150px;
  height: 200px;
  background: white;
  top: 30px;
  right: 0;
  z-index: 10;
  transition: 0.1s ease-out;
  overflow-y: scroll;
  border: 1px solid var(--line-color);
}

.special-shape-dropdown-visible::-webkit-scrollbar {
  width: 5px;
}

.special-shape-dropdown-visible::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.special-shape-dropdown-visible::-webkit-scrollbar-thumb {
  background: #888;
}
.special-shape-dropdown-visible::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.special-shape-dropdown-visible li {
  font-size: 0.7rem;
  list-style: none;
  padding: 5px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--line-color);
  transition: 0.1s ease-out;
}
.active-li-shape {
  background-color: var(--bright-color);
  font-weight: 700;
}
.special-shape-dropdown-visible li:hover {
  background-color: var(--bright-color);
  color: #ffff;
}

.dropdown-set-arrow {
  color: var(--line-color);
  padding: 2px 0px 4px 4px;
  position: absolute;
  top: -2px;
  z-index: 12;
}
.button-dropdown-menu {
  color: var(--line-color);
  padding: 2px 0px 4px 4px;
  position: absolute;
  top: -10px;
  right: 1px;
  z-index: 12;
}

.button-dropdown-menu:hover {
  color: var(--dark-blue-color);
}
.shape-filter-span {
  font-size: 0.6rem;
  white-space: nowrap;
  font-weight: 800;
}

.special-shape-icon {
  transform: scale(1.5);
  margin-top: 4px;
}
.special-shape-icon-pair {
  transform: scale(3);
  margin-top: 4px;
}

.copyLink {
  background-color: var(--main-color);
  color: white;
  visibility: hidden;
  white-space: nowrap;
  opacity: 0;
  transition: 0.5s ease-out;
  padding: 0 3px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.copyLink.active {
  visibility: visible;
  opacity: 1;
}
.copy-link-component {
  bottom: 6px;
  left: 5px;
  z-index: 30;
}

.shrink-columns {
  width: 0px !important;
  flex-basis: 1 1 100%;
}
.confirm-delete {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
  text-align: "center" !important;
  width: 130px;
  padding: 4px;
  background-color: var(--bg-color);
  border: 1px solid var(--line-color);
  top: -70px;
  left: -122px;
  z-index: 15;
}

.confirm-delete p {
  font-size: 0.8rem;
  text-align: center;
  color: var(--main-color);
}

.confir-delete div {
  display: flex;
  justify-content: space-between;
}

.make-unclickable {
  pointer-events: none;
  opacity: 0.5;
}

.realign-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.back-button-wrapper {
  text-decoration: none !important;
  color: var(--dark-blue-color);
  margin-top: 10px;
}
.back-button-wrapper:hover {
  color: var(--bright-color);
}

/* ---------------------------------------------------------SIDE MENU--------------------------------------------------------- */

.side-menu-container {
  position: fixed;
  background-color: var(--bg-color);
  border: 1px solid var(--line-color);
  border-left: none;
  transition: 0.1s ease-out;
  z-index: 99;
}
.side-menu-container.unfolded {
  -webkit-box-shadow: 4px 1px 8px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 0px 10px -1px rgba(0, 0, 0, 0.3);
}
.side-menu-navigation {
  display: none;
  padding: 63px 4px 0 5px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50px;
  height: 100vh;
  transition: 0.1s ease-out;
}
.side-menu-navigation.unfolded {
  width: 180px;
}
.side-menu-navigation ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: "100%" !important;
}

.side-menu-navigation a {
  text-decoration: none;
}
.side-menu-link {
  display: block;
  list-style: none;
  text-align: left;
  padding: 11px 8px;
  color: #fff;
  text-decoration: none;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  cursor: pointer;
  font-size: 20px;
}

.side-menu-link:hover {
  color: var(--bright-color) !important;
  text-decoration: none;
}

.side-menu-link:hover .side-menu-link-span {
  color: var(--bright-color) !important;
}

.side-menu-link-span {
  font-size: 13px;
  text-size-adjust: none;
  font-weight: 500;
  max-height: 25px;
  overflow-y: hidden;
  padding-left: 12px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-out;
  white-space: nowrap;
  color: #000;
  font-family: "Font Awesome 5 Free";
}
.side-menu-link-span.unfolded {
  visibility: visible;
  opacity: 1;
}

.side-menu-navigation li {
  width: 100%;
  list-style: none;
  /* color: var(--dark-blue-color) !important; */
  transition: 0.2s;
  overflow: hidden;
}

.side-menu-span {
  margin-right: 20px;
  transition: 0.3s ease-out;
}

.top-menu-container {
  position: fixed;
  width: 100%;
  background-color: var(--bg-color);
  z-index: 1510;
  padding-right: 0 !important;
}

.navigation-container {
  margin-bottom: 200px;
  z-index: 151;
}

.side-navigation-arrow {
  position: absolute;
  color: var(--dark-blue-color);
  background-color: white;
  transform: rotateZ(180deg);
  transition: 0.3s ease-out;
}
.side-navigation-arrow:hover {
  color: var(--bright-color);
}
.side-navigation-arrow.rotate {
  transform: rotateZ(0deg);
}

.collapsed-link {
  display: block;
  color: var(--dark-blue-color);
  text-align: left;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  height: 0;
  padding: 0 0 0 10px;
  width: 100%;
  transition: padding 0.3s, visibility 0.3s, opacity 0.3s;
  overflow: hidden;
  border-left: 0.5px solid var(--dark-blue-color);
  font-family: "Font Awesome 5 Free";
}

.collapsed-link:hover {
  color: var(--bright-color) !important;
  border-radius: 6px;
  border: none;
}

.collapsed-link.show {
  visibility: visible;
  opacity: 1;
  height: auto;
  padding: 1px 0 1px 10px;
}

.basket-link-circle span {
  /* position: absolute; */
  text-align: center;
  width: 19px;
  height: 19px;
  background-color: var(--dark-blue-color);
  color: white;
  /* bottom: 3px; */
  left: 20px;
  font-size: 9px;
  border-radius: 50%;
  font-weight: bold;
  line-height: 20px;
  box-sizing: border-box;
  font-family: sans-serif;
  transform: scale(1);
}
.side-menu-link:hover .basket-link-circle {
  background-color: var(--bright-color);
}

.login-menu-item-left {
  display: flex;
  margin-top: 67px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
  padding: 15 !important;
  overflow-x: hidden;
}
.social-networks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  padding-left: 9px !important;
  margin: 0;
  border-bottom: 0.5px solid var(--line-color);
}

.social-button {
  text-decoration: none;
  color: var(--dark-blue-color);
}
.social-button:hover {
  color: var(--bright-color);
}
.social-button.fb {
  margin-left: 15px;
  font-size: 24px;
}

.social-button.gram {
  margin-left: 5px;
  font-size: 28px;
  padding-top: 2px;
}

.matching-pairs-icon-container {
  position: relative;
  font-size: 16px;
  transform: translatex(-4px);
}

.matching-pairs-icon {
  left: 13px;
  top: 8px;
}

/* ================================================ End of Side Menu ============================================ */

.parcel-box-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  background-color: white;
}
.btn.cancel {
  border-color: var(--dark-blue-color);
  color: var(--dark-blue-color);
  background-color: transparent;
}

.btn.cancel:hover {
  border-color: var(--bright-color);
  color: var(--bright-color);
}

.btn.addToBasket {
  width: 100%;
  border-color: var(--dark-blue-color);
  background-color: var(--dark-blue-color);
  color: #fff;
}

.btn.addToBasket:hover {
  border-color: var(--bright-color);
  background-color: var(--bright-color);
}

.btn.add-to-cart.added {
  border-color: var(--bright-color);
  background-color: var(--bright-color);
}

.parcel-box-calculations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  margin-bottom: 21px;
}

.parcel-box-calculations h4 {
  font-size: 1.1rem;
  margin: 0;
}
.parcel-box-calculations h4 {
  margin-bottom: 7px;
}
.parcel-box-calculations h4:last-child {
  margin-bottom: 0px;
}

.show-status {
  font-family: "Oswald", sans-serif;
  font-weight: bolder;
  font-size: 1.1rem;
}

.white-fade {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 600px;
  left: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 0.7) 88%,
    rgb(255, 255, 255) 100%
  );
}

.collapsed-row {
  background-color: "red" !important;
}
.card-expand {
  flex: 0 0 100%;
  max-width: 100%;
}

.float-control {
  float: left;
}
.melee-color-box-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--secondary-font-family);
  height: 100px;
  border: 1px solid var(--bright-color);
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s ease;
  margin-right: 4px;
}
.melee-color-box-container:last-child {
  margin-right: 0;
}

.question-mark-circle {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.3rem;
  color: black;
  border-radius: 50%;
  padding: 2px 7px 1px 7px;
  background-color: white;
  font-family: sans-serif;
  font-weight: bolder;
  cursor: help;
}

.melee-description-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: left;
}

.melee-description-button-wrapper {
  display: flex;
  float: right;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.melee-width-fix {
  width: 155px !important;
  font-weight: bold !important;
  font-size: 1rem !important;
}
.melee-description-first-column,
.melee-description-second-column {
  width: 250px;
}
.melee-color-title {
  color: var(--dark-blue-color);
  font-family: var(--font-family);
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  white-space: nowrap !important;
}

.melee-description-first-column .description-secondary,
.melee-description-second-column .description-secondary,
.melee-description-first-column .description-position-fix,
.melee-description-second-column .description-position-fix,
.melee-description-button-wrapper .single-item-description-item .description-primary,
.melee-description-button-wrapper .single-item-description-item .description-secondary {
  font-weight: bold;
  font-size: 1rem;
}
.melee-addToBasket-button {
  width: 243px;
}

.custom-tooltip {
  position: absolute;
  font-size: 0.7rem;
  width: 100px;
  z-index: 10;
  color: var(--light-grey-color);
  line-height: 17px;
  background-color: var(--dark-blue-color);
  padding: 10px;
  font-family: var(--font-family);
  text-transform: none;
  right: 20px;
  top: -2px;
  pointer-events: none;
}
.custom-tooltip.left {
  right: 0;
  left: 40px;
}
.custom-tooltip p {
  margin: 0;
}

.set-expand {
  background-color: #ececec !important;
}

.set-expand-greyed {
  background-color: #dadada !important;
}

.set-expand td {
  color: black !important;
}

.set-expand button:hover {
  color: white !important;
}
.invite-user-container {
  display: flex;
  align-items: center;
  background-color: var(--bg-color);
  min-height: 150px;
}
.overflow-card {
  overflow: auto;
}

.floating-arrow {
  color: var(--bright-color);
  background-color: white;
  border-radius: 100%;
  visibility: visible;
  opacity: 1;
  position: absolute;
  bottom: 20px;
  right: 30px;
  cursor: pointer;
  transition: 0.3s ease;
  z-index: 160;
}

.floating-arrow.hideArrow {
  visibility: hidden;
  opacity: 0;
}

/* ================================================ END OF DESKTOP CUSTOM ============================================ */
@media screen and (max-width: 1631px) {
  .display-width-diamonds {
    max-width: 95%;
  }
  .list-view-container {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .list-view-container-memo {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .navbar-collapse {
    display: flex !important;
    flex-basis: 0;
  }
  .navbar-collapse .navbar-nav {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .navbar-toggler {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .single-item-description-item,
  .single-item-description-item-right {
    margin-top: 15px;
  }

  .single-item-description-item:first-child,
  .single-item-description-item-right:first-child {
    margin-top: 10px;
  }
  .single-item-description-item .description-primary {
    display: inline-flex;
  }
  .single-item-description-item-right .description-primary {
    display: inline-flex;
  }
  .single-item-description-item,
  .single-item-description-item-right {
    white-space: normal;
  }
}
@media screen and (max-width: 1179px) {
  .back-button-wrapper {
    text-decoration: none;
    color: var(--dark-blue-color);
    margin-top: 0px;
  }
  .navbar-collapse {
    display: none !important;
  }
  .navbar-toggler {
    display: block;
  }
  .side-menu-container {
    width: unset;
    left: "unset";
    right: -50px;
    border-left: 1px solid var(--line-color) !important;
  }
  .side-menu-navigation.unfolded {
    display: flex;
    width: 180px;
    right: 0;
  }
  .side-menu-container.unfolded {
    width: 180px;
    right: 0;
    box-shadow: none;
    /* -webkit-box-shadow: -1px 0px 10px -1px rgba(0, 0, 0, 0.3); */
    /* box-shadow: -1px 0px 10px -1px rgba(0, 0, 0, 0.3); */
  }
  .side-menu-span {
    margin-right: 0;
  }
  .side-menu-link {
    color: #000;
    padding: 9px 8px;
  }

  .display-width-diamonds {
    max-width: 100%;
  }
  .modal-3d-view-container-cert {
    width: 600px;
    height: 500px;
  }
  .settingsContainer {
    flex: 0 0 100%;
    width: 100%;
  }
  .settingsContainer.foldedSideMenu {
    flex: 0 0 100%;
    width: 100%;
  }

  .fas.fa-chevron-circle-left.position-absolute {
    display: none;
  }

  .header-image {
    background-image: url(../images/home_image.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  /* .carousel-item {
    background-image: url(../images/header1.jpg);
  } */

  .carousel-card .card-body .card-icons {
    display: none;
  }

  .navbar {
    padding: 0;
  }

  .nav-item {
    text-align: center;
  }

  @media screen and (min-width: 1430px) {
    .side-menu-link-span {
      background-color: rgb(0, 0, 0) !important;

      font-size: 12px !important;
    }
    .unfolded {
      background-color: black !important;

      font-size: 12px !important;
    }
  }

  .navbar-collapse {
    border-top: 1px solid var(--line-color);
  }

  .login-menu {
    display: none;
  }

  .login-menu-item-collapse {
    display: block;
    text-align: center;
    font-size: 12px;
    letter-spacing: 2px;
    background-color: #c3c3c326;
  }

  .login-form-container,
  .wishlist-prieview-container,
  .cart-preview-container {
    padding: 1em 2em;
  }

  .navbar {
    padding-bottom: 0;
  }

  .nav-list {
    background-color: var(--bg-color);
  }
  /* 
  .header-image {
    width: 100%;
  } */

  .brand-logo {
    margin-left: 0.6rem;
  }

  .section-name {
    font-size: 120px;
    margin-top: -46px;
  }

  .option-container {
    margin: 0 auto;
    margin-top: -30px;
    width: 62%;
  }

  .reset-pass form,
  .reset-msg p {
    width: 70%;
  }

  .breadcrumb {
    padding: 20px 4% 6px;
  }

  .details-container,
  .overview-container {
    width: 100%;
    border: none;
  }

  .side-bar {
    display: none;
  }

  .sidebar-toggle {
    display: block;
  }

  .wishlist-container {
    border: none;
  }

  .order-history-table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .order-history-table-responsive-xl > .order-history-table-bordered {
    border: 0;
  }

  .order-history-table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .order-history-table-responsive > .order-history-table-bordered {
    border: 0;
  }

  .customer-message {
    width: 98%;
  }

  .shows-container {
    padding: 0 2%;
    margin: auto;
  }

  .cut-slider-data,
  .color-slider-data,
  .clarity-slider-data,
  .polish-slider-data,
  .symmetry-slider-data,
  .fluor-slider-data {
    padding-bottom: 38px;
  }

  .extra-controls,
  .extra-controls .row,
  .extra-controls .col-md-7,
  .extra-contols .col-md-5 {
    padding: 0;
  }

  #product-table {
    min-width: 991px;
  }

  .results-container {
    margin-left: 0;
    margin-right: 0;
    border-left: none;
    padding-left: 7px;
  }

  .advOptions {
    margin-right: 3%;
  }

  .button-reset {
    display: block;
  }

  #advSettings {
    width: 98%;
  }

  .lab-filter li,
  .fluor-filter li {
    padding: 2px 12px;
  }

  .results-container .col-xl-12,
  #pills-tabContent {
    padding-right: 0;
  }

  .filter-shape-item .shape-icon svg {
    width: 18px;
    height: 18px;
  }

  .product-shape-wrap {
    margin-top: 38px;
  }

  .color_diamonds_container {
    margin-top: 10px;
  }

  .whatsappShare-inline {
    margin-left: 6%;
  }

  .whatsappShare-inline,
  .addToWishList-inline,
  .holdBtn-inline {
    padding: 0 6%;
    padding-bottom: 10px;
  }

  .wishlist-items-container {
    margin-left: 5%;
  }
}

/* DESKTOP SPECIFIC STYLES. Used for hover effects as they break mobile */
@media screen and (min-width: 991.99px) {
  .select-form-filter .btn-toolbar button:hover {
    color: var(--bg-color) !important;
    border-color: var(--bright-color) !important;
    background-color: var(--bright-color) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .select-form-filter .btn-toolbar button:hover + button {
    border-left-color: var(--bright-color);
  }
}

/* Ipad Pro view */
@media screen and (max-width: 1025px) {
  .single-item-grid-container {
    padding: 20px 0px 0 15px;
    max-width: 100px;
  }
}

@media screen and (max-width: 991.98px) {
  .single-item-iframe-cert {
    height: 600px;
  }
  .single-item-description-item .description-primary {
    display: inline;
  }
  .single-item-description-item-right .description-primary {
    display: inline;
  }
  .float-control {
    float: none;
  }
  .single-item-image-select {
    font-size: 10px;
    max-width: 70px;
    max-height: 70px;
  }
  .shape-filter-span {
    display: none;
  }

  .special-shape-icon,
  .special-shape-icon-pair {
    margin-top: 0px;
  }

  .wrap-buttons-list-view {
    flex-wrap: wrap;
  }
  .grid-container {
    grid-template-areas: unset;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: unset;
  }
  .single-item-description-item {
    white-space: normal;
  }

  /* .grid-container .grid-item-title {
    grid-area: none;
  } */

  .grid-container .grid-item-cart {
    grid-area: unset;
  }
  .grid-container .grid-item-memo {
    grid-area: unset;
  }

  .grid-container .grid-item-product {
    grid-area: unset;
  }

  .grid-container .grid-item-account {
    grid-area: unset;
  }

  .grid-container .grid-item-order {
    grid-area: unset;
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  .section-name {
    font-size: 90px;
    margin-top: -36px;
  }

  .option-container {
    width: 77%;
  }

  .category-title {
    font-size: 1.5em;
  }

  .footer {
    font-size: 8px;
  }

  .register-form-title {
    margin-top: 50px;
    margin-left: 19%;
    margin-bottom: 200px;
  }

  .order-history-table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .order-history-table-responsive-lg > .order-history-table-bordered {
    border: 0;
  }

  .wishlist-items-container {
    display: flex;
    justify-content: center;
  }

  .about-container p {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .cart-container {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .remove-fixed-bottom {
    position: relative;
  }

  .whatsappShare-inline,
  .addToWishList-inline,
  .holdBtn-inline {
    padding: 0 10%;
    padding-bottom: 10px;
  }

  .detail-property {
    margin-left: 14%;
    margin-top: 48px;
  }

  .image-preview {
    margin: 0 auto;
  }

  .wishlist-items-container {
    margin-left: 0%;
  }

  .button-reset button {
    width: 100%;
  }

  .dropdowns {
    width: 97%;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
  }

  #sort,
  #items-per-page {
    float: none;
    width: 100%;
    display: block;
  }

  #sort {
    margin-left: 1%;
  }
}

@media screen and (max-width: 767.98px) {
  .single-item-grid-container {
    padding: 20px 0px 0 0px;
    max-width: 100%;
  }

  .single-item-description-mobile {
    order: 3;
  }

  .single-item-description-item .description-primary {
    display: inline-block;
    width: 110px;
  }
  .single-container-top {
    display: none;
  }
  .single-container-bottom {
    display: grid;
  }
  .section-name {
    font-size: 70px;
    margin-top: -32px;
  }

  .container-fluid .row .home-image {
    max-height: 400px;
  }

  .shop-by-shape-container {
    margin-bottom: 40px;
  }

  .newsletter input.contact_email[type="email"] {
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 12px;
    margin-left: 2%;
  }

  #contact_form .action_button.sign_up {
    width: 100%;
    margin-left: 2%;
  }

  .diamonds-shape-container {
    margin-left: -2%;
  }

  .box-account .col-1,
  .box-account .col-2 {
    display: block;
    width: 100%;
  }

  .box-title,
  .box-content,
  .box-content-2 {
    width: 100%;
  }

  .box-content,
  .box-content-2 .col-1 {
    margin-bottom: 30px;
  }

  .order-history-table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .order-history-table-responsive-md > .order-history-table-bordered {
    border: 0;
  }

  .customer-message {
    margin-left: 0;
  }

  .contact-container {
    padding: 0 5%;
  }

  .button-reset {
    text-align: left;
    padding-right: 0px;
  }

  .image-container {
    margin-top: 0;
  }

  .dropdown-wrapper {
    padding: 0;
  }

  .center-item {
    top: 0;
    position: relative;
  }

  .filter-shape-item {
    width: 17%;
  }

  .detail-property {
    margin-left: 7%;
    margin-right: -11%;
  }

  #sort {
    margin-left: 1.3%;
  }

  .image-preview {
    padding-left: 4%;
  }
  .summary-bar {
    padding-top: 5px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    text-align: center;
  }
}

@media screen and (max-width: 575.98px) {
  .custom-tooltip {
    width: 130px;
  }

  .melee-addToBasket-button {
    width: auto;
  }
  .melee-color-title {
    font-size: 1.2rem;
  }
  .melee-width-fix {
    width: 115px !important;
  }
  .melee-description-button-wrapper {
    align-items: flex-start;
  }
  .single-item-description-item .description-position-fix {
    margin: 0;
  }
  .melee-description-first-column .description-position-fix,
  .melee-description-first-column .description-position-fix {
    margin: 0 !important;
  }
  .melee-description-container {
    justify-content: start;
  }
  .question-mark-circle {
    padding: 0px 5px 0px 5px;
  }
  .single-item-iframe-cert {
    width: 100%;
    height: 300px;
  }
  .single-item-grid-container {
    padding: 20px 0px 0 0px;
    max-width: 100%;
  }
  .special-shape-icon {
    transform: scale(1);
  }
  .special-shape-icon-pair {
    transform: scale(1.5);
  }

  .modal-3d-view-container {
    width: 300px;
    height: 320px;
  }
  .modal-3d-view-container-cert {
    width: 350px;
    max-height: 310px;
  }
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .section-name {
    display: none;
  }

  .login-form form,
  .register-form-container form,
  .login-form .btn-custom-outline {
    width: 100%;
  }

  .register-form-title {
    margin: 0;
  }

  .login-form-title {
    margin-left: 0;
  }

  .reset-pass form,
  .reset-msg p {
    width: 100%;
  }

  .dl-horizontal dd {
    margin-left: 0;
  }

  .dl-horizontal dt {
    float: none;
  }

  .details-container dd {
    padding-bottom: 3px;
    margin-top: -4px;
  }

  .reg-form {
    width: 100%;
  }

  .order-history-table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .order-history-table-responsive-sm > .order-history-table-bordered {
    border: 0;
  }

  .order-history-table-container {
    padding: 0 24px;
  }

  .addNewAddress {
    float: none;
    margin-bottom: 40px;
  }

  .my-account-container {
    padding: 0;
  }

  .overview-container {
    padding: 10px;
  }

  .customer-message {
    width: 93%;
    margin: auto;
  }

  .column-product {
    margin-left: 14%;
  }

  .column,
  .column-product {
    width: 100%;
    display: block;
  }

  .diamond-search-container {
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .button-reset {
    text-align: center;
    padding-left: 0;
  }

  .button-reset button {
    width: 90%;
  }

  .image-container,
  .text-details {
    width: 50%;
  }

  .buttons-cart {
    min-width: 320px;
    margin-left: -41px;
  }

  .filter-shape-item {
    width: 22%;
  }

  .second-column {
    margin-top: -18px;
  }

  .whatsappShare-inline,
  .addToWishList-inline,
  .holdBtn-inline {
    padding: 0 5%;
    padding-bottom: 10px;
  }

  .whatsappShare-inline {
    margin-left: 7%;
  }

  #sort {
    margin-left: 1.9%;
  }

  .dropdowns {
    width: 100%;
    margin-left: -5px;
  }

  .image-preview {
    max-width: 342px;
  }
  .diamond-fancy-image {
    width: 12px;
    height: 12px;
  }
  .select-form-filter .btn-toolbar .fancy-intensive-text-size {
    font-size: 0.45rem;
    font-weight: bold;
  }
}
@media screen and (max-width: 475.98px) {
  .select-form-filter {
    grid-template-columns: none;
    grid-gap: 0;
    gap: 0;
  }
  .select-form-filter label {
    text-align: center;
  }
  .button-dropdown-menu {
    top: 30px;
    right: 2px;
  }
  .special-shape-dropdown-visible {
    top: 60px;
  }
  .margin-control {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .select-form-filter label {
    font-weight: bolder;
  }
  .label-text-bolder {
    font-weight: bolder;
  }
}

/* MELEE BY SIZE: START */
.radio-button .MuiRadio-colorSecondary.Mui-checked {
  color: var(--dark-color);
}
/* MELEE BY SIZE: END */

/* Hiding columns on mobile when expanding set */
@media screen and (max-width: 576px) {
  .mobile-hidden-list-view {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .mobile-hidden-list-view-status {
    display: none;
  }
}
/* @media screen and (max-width: 1900px) {
  .li-orderlist-styling {
    font-size: 10px;
  }
} */

.summaryRowDesign {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media print {
  @page {
    size: landscape;
  }
}
.pushNotification {
  color: black;
  z-index: 152;
  margin: 0px auto;
  position: absolute;
  background-color: #ff9d9d;
  cursor: pointer;
}

.section-steps {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  color: #fff;
}

.section-steps-imageContainer {
  width: 60px;
  height: 60px;
  position: relative;
}
.section-steps-imageContainer img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.engagementRings {
  width: 80%;
  margin: 0 auto;
  /* background-color: red; */
}
.engagementRings-filter {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: rgb(115, 115, 115); */
}
.engagementRings-header-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
}
.engagementRings-resetFilter {
  color: #0e2343;
  font-weight: bold;
  cursor: pointer;
}

.engagementRings-button {
  border: 1px solid gray;
}
.engagementRings-button:hover {
  /* background-color: rgba(87, 87, 87, 0.06) !important; */
  opacity: 0.7;
}
.engagementRings-button:focus {
  outline: none;
  box-shadow: none;
}
.btn.focus {
  box-shadow: none !important;
}
@media screen and (max-width: 975px) {
  .engagementRings-filter {
    display: none;
  }
}
.ring-container .btn {
  height: 100%;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  border-radius: 0;
  border: 1px solid;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 8px;
}

.ring-container .btn.details {
  border-color: var(--dark-blue-color);
  color: var(--dark-blue-color);
  background-color: transparent;
}

.ring-container .btn.details:hover {
  border-color: var(--bright-color);
  color: var(--bright-color);
}

.ring-container-active .btn {
  height: 100%;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  border-radius: 0;
  border: 1px solid;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 8px;
}

.ring-container-active .btn.details {
  border-color: var(--dark-blue-color);
  color: var(--dark-blue-color);
  background-color: transparent;
}

.ring-container-active .btn.details:hover {
  border-color: var(--bright-color);
  color: var(--bright-color);
}

.select-setting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 90%;
  background-color: #5f8eb1;
  padding: 8px;
  border-radius: 2px;
  margin: 2px;
}
.select-setting-active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #0e2343;
  padding: 8px;
  border-radius: 2px;
  margin: 2px;
}

.karat-button {
  margin: 1px;
  border: none;
  outline: none;
  background-color: rgb(225, 225, 225);
}
.karat-button-active {
  margin: 1px;
  border: none;
  outline: none;
  background-color: rgb(189, 189, 189);
}
.ring-setting-info-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 12px;
}
.ring-setting-buttons {
  display: flex;
  flex-direction: column;
}
.ring-setting-buttons-active {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ring-settings {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}
.ring-container {
  width: 264px;
  margin: 6px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.ring-container-active {
  display: flex;
  justify-content: space-around;
  width: 98.5%;
  height: 420px;
  margin: 6px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.single-setting {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
}
.single-setting-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.single-setting-title {
  color: #000;
  font-weight: bold;
  margin: 8px 0px;
}
.single-setting-row-info {
  font-weight: bold;
}
button:focus {
  outline: none !important;
}
/* button:focus {
  outline: none;
  background-color: var(--lgtr-blue);
} */

.completeRing {
  margin-top: 18px;
  display: flex;
  justify-content: space-evenly;
}
.completeRing-wrap {
  display: flex;
  /* background-color: #2a6fa4; */
}
.completeRing-pictures {
  display: flex;
  flex-direction: column;
  max-width: 140px;
}
.completeRing-pictures-single {
  margin-right: 10px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 5px rgba(169, 169, 169, 0.8);
  cursor: pointer;
}
.completeRing-picture-wrap {
  max-width: 525px;
}
.completeRing-picture {
  width: 100%;
  max-height: 500px;
  height: auto;
  box-shadow: 1px 1px 5px rgba(169, 169, 169, 0.8);
}
.completeRing-wrap-info {
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* padding: 22px;  */
  box-shadow: 1px 1px 5px rgba(169, 169, 169, 0.8);
}
.completeRing-info-header {
  max-width: 350px;
  padding: 14px 22px;
  text-align: center;
}
.completeRing-setting-diamond-wrap {
  margin-top: 18px;
  /* padding-top: 20px; */
  display: flex;
  justify-content: space-evenly;
}
.completeRing-setting-diamond-wrap-parent {
  background: linear-gradient(0deg, transparent, rgba(238, 238, 238, 0.6) 90%) no-repeat;
}
.completeRing-setting-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-weight: bold; */
}
.completeRing-diamond-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-weight: bold; */
}
.ring-size-dropdown {
  height: 50px;
  display: inline-block;
}
.ring-size-input {
  height: 50px;
  padding-top: 15px;
}
.completeRing-ring-size {
  margin-top: 18px;
  display: flex;
  justify-content: center;
}

.completeRing-total {
  margin-top: 18px;
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
}
.completeRing-change-button {
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 10px 0;
  background-color: #5f8eb1;
  color: white;
}
.completeRing-order-buttons {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 18px auto;
}
.completeRing-order-buttons .btn.details {
  border-color: var(--dark-blue-color);
  color: var(--dark-blue-color);
  background-color: transparent;
}
.completeRing-order-buttons .btn.details:first-child {
  margin-bottom: 6px;
}

.completeRing-order-buttons .btn.details:hover {
  border-color: var(--bright-color);
  color: var(--bright-color);
}

.completed-rings .btn {
  height: 100%;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  border-radius: 0;
  border: 1px solid;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 8px;
}
.completed-rings .btn.details {
  border-color: var(--dark-blue-color);
  color: var(--dark-blue-color);
  background-color: transparent;
}
.completed-ring-configuration-buttons .btn {
  height: 100%;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  border-radius: 0;
  border: 1px solid;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 8px;
}
.completed-ring-configuration-buttons .btn.details {
  border-color: var(--dark-blue-color);
  color: var(--dark-blue-color);
  background-color: transparent;
}

@media screen and (max-width: 700px) {
  .section-steps {
    flex-direction: column;
    font-size: 18px;
  }
  .engagementRings {
    width: 100%;
  }
  .completeRing {
    flex-direction: column;
  }
  .completeRing-pictures {
    flex-direction: row;
  }
  .completeRing-pictures-single {
    max-width: 160px;
  }
  .completeRing-wrap-info {
    margin-top: 10px;
  }
  .completeRing-info-header {
    max-width: none;
  }
  .completed-ring-configuration-wrap-info {
    margin: 0 !important;
  }
}

.completed-ring-configuration-wrap-info {
  min-width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 12px;
  margin: 0 10px;
  box-shadow: 1px 1px 5px rgba(169, 169, 169, 0.8);
}
.completed-ring-configuration-model-price {
  display: flex;
  justify-content: space-between;
  /* font-size: 18px; */
  /* font-weight: bold; */
}
.completed-ring-configuration-select-gold {
  display: flex;
  justify-content: space-evenly;
}

.completed-ring-configuration-select-gold-container {
  display: flex;
}
.completed-ring-configuration-select-gold-container p {
  padding: 0 6px;
  cursor: pointer;
}

.completed-ring-configuration-diamond-shape-wrap {
  display: flex;
  flex-direction: column;
}
.completed-ring-configuration-diamond-shapes {
  display: flex;
  justify-content: center;
}
.completed-ring-configuration-diamond-shapes-select {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}
.completed-ring-configuration-diamond-shapes-select p {
  /* padding: 0 8px; */
  text-align: center;
}
.completed-ring-configuration-diamonds-color {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.completed-ring-configuration-buttons {
  display: flex;
  justify-content: space-evenly;
}
.completed-ring-configuration-bold {
  font-weight: bold;
}

.carousel .control-next.control-arrow:hover {
  background-color: transparent !important;
}
.carousel.carousel-slider .control-arrow:hover {
  background-color: transparent !important;
}

.hidden-sidebar {
  display: none;
}
